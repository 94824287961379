import React from "react"
import { ItemSlider } from "@components/shared"
import { Link } from "gatsby"

import { button__primary } from "@styles/button.module.scss"
import { icon__small, paragraph__gray } from "@styles/typography.module.scss"
import { wrapper } from "./styles/section.module.scss"
import {
  background,
  hero,
  hero__wrapper,
  hero__wrapper__image,
  hero__wrapper__content,
  sliderWrapper,
  heroSlider,
} from "./styles/hero.module.scss"

import { HelmetSVG, CyberSecuritySVG, TimeSVG, RocketSVG } from "./icons"
const translation = {
  pl:{
    heading_text:"Great Gatsby Store",
    description_text:"Dla właścicieli cyfrowych firm jesteśmy jak zbroja dla Iron-Mana" ,
    button_text:"Jak budujemy i ulepszamy takie zbroje?"
  },
  en:{
    heading_text:"Great Gatsby Store",
    description_text:"For digital business owners we are like armor for Iron-Man",
    button_text:"How do we build and improve such armors?"
  }
}

const heroList = [
  {
    name: "Security",
    icon: CyberSecuritySVG,
    pl:{
      content:"Chronimy właściciela w technologicznym świecie i zwielokrotniamy jego możliwości",
    },
    en:{
      content:"We protect the owner in the technological world and multiply his capabilities and profits"
    }   
  },
  {
    name: "Time",
    icon: TimeSVG,
    pl:{
      content:"Dostarczamy błyskawicznie wartościowych informacji i prawdopodobnych scenariuszy działania",
    },
    en:{
      content:"We provide instantaneous valuable information and probable scenarios for action",
    }
  },
  {
    name: "Rocket",
    icon: RocketSVG,
    pl:{
      content:"Jeśli ma chęć polecieć w kosmos, to odpalamy silniki odrzutowe, zapinamy pasy i stabilizujemy lot",
    },
    en:{
      content:"If he wants to fly into space, we start the jet engines, fasten belts and stabilize the flight",
    }
  }
]

const Hero: React.FC = ({ language }): JSX.Element => {
  console.log(heroList[0][language].content)
  return (
    <div className={wrapper}>
      <section className={hero}>
        <div className={hero__wrapper}>
          <div className={hero__wrapper__content}>
            <h1>{translation[language].heading_text}</h1>
            <p>{translation[language].description_text}</p>
            <ul>
              {heroList.map((el) => (
              <li key={el.name}>
                <img alt={el.name} src={el.icon} className={icon__small} />
                <p className={paragraph__gray}>{el[language].content}</p>
              </li>
            ))}
            </ul>
          </div>

          <div className={hero__wrapper__image}>
            <img alt="helmet" src={HelmetSVG} />
          </div>

          <div className={sliderWrapper}>
            <ItemSlider
              items={heroList}
              observeValue={(i) => i.name}
              className={heroSlider}
              name="hero"
            />
          </div>
        </div>

        <Link to="/" className={button__primary} type="button">
          {translation[language].button_text}
        </Link>
      </section>
    </div>
  )
} 


export default Hero
